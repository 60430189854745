<template>
  <div
    style="
      border: 1px solid #f3f3f3;
      border-radius: 10px;
      padding: 10px;
      background-color: #ffffff;
      margin: 5px;
    "
  >
    <div
      style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f3f3f3;
        padding-bottom: 10px;
      "
    >
      <div>
        <van-image
          round
          width="54"
          height="54"
          src="https://img01.yzcdn.cn/vant/cat.jpeg"
        />
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          margin-left: 10px;
        "
      >
        <div>
          <div style="display: flex; align-items: center; margin-bottom: 5px">
            <van-tag type="danger" size="mini" round class="linear-btn-color"
              >默认</van-tag
            >
            <span class="color333 fz16" style="margin: 5px">假话全不说</span>
            <copy-text text="假话全不说">
              <van-icon name="orders-o" />
            </copy-text>
          </div>
          <div class="fz12 color999">
            <span>123***1234</span>
            <span style="margin-left: 5px">2021年04月21日</span>
          </div>
        </div>
        <van-button
          color="#ff5e00"
          size="mini"
          plain
          round
          @click="contact"
          style="width: 56px"
          >联系TA</van-button
        >
      </div>
    </div>
    <div
      style="display: flex; justify-content: space-evenly; margin: 15px 0 5px"
    >
      <div>
        <span>粉丝</span>
        &nbsp;
        <span>2</span>
      </div>
      <span> | </span>
      <div>
        <span>有效粉丝</span>
        &nbsp;
        <span>2</span>
      </div>
    </div>

    <van-dialog v-model="show" title="联系TA">
      <div style="padding: 15px 25px">
        <div>
          <span>手机号：</span>
          <span>13355555555</span>
          &nbsp;
          <copy-text text="13355555555">
            <van-icon name="orders-o" />
          </copy-text>
        </div>
        <div>
          <span>微信号：</span>
          <span>微信号暂未绑定</span>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import copyText from "@/components/copy-text";
export default {
  name: "fansItem",
  data() {
    return {
      show: false,
    };
  },
  components: { copyText },
  created() {},
  methods: {
    // 联系TA 弹框
    contact() {
      this.show = true;
    },
  },
};
</script>

<style lang="less" scoped>
</style>