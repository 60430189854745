<template>
  <div>
    <!-- 导航 -->
    <van-nav-bar
      title="粉丝管理"
      left-text=""
      left-arrow
      :fixed="true"
      placeholder
    >
      <template #right>
        <van-icon name="replay" size="18" color="black" />
      </template>
    </van-nav-bar>
    <!-- 导航 -->
    <div>
      <!-- 点击tap栏 -->
      <div
        style="display: flex; align-items: center; background-color: #ffffff"
      >
        <div style="width: 100%">
          <van-tabs
            v-model="active"
            color="#FF5200"
            title-active-color="#FF5200"
            line-width="10px"
          >
            <van-tab title="全部"> </van-tab>
            <van-tab title="直邀"></van-tab>
            <van-tab title="推荐"></van-tab>
            <van-tab title="潜在"></van-tab>
          </van-tabs>
        </div>
        <!-- <div style="margin-left: 10px; position: relative; right: 12px">
        <van-icon name="search" size="18" color="black" />
      </div> -->
      </div>
      <!-- 点击tap栏 -->
      <div style="height: 15px; background-color: #f6f6f6"></div>

      <div>
        <fans-item v-for="(item, index) in 10" :key="index"></fans-item>
      </div>
    </div>
  </div>
</template>

<script>
import fansItem from "./components/fans-item";
export default {
  name: "fansmanagement",
  components: { fansItem },
  data() {
    return {
      active: 0, //tap栏
    };
  },
  created() {},
};
</script>
<style  lang="less" scoped>
.van-nav-bar .van-icon {
  color: black;
}
.van-tab--active {
  font-size: 18px;
  color: #ff9765;
}
</style>
